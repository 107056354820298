export const useAchivements = () => {

    const education = [
        {
            title: 'SkillBox',
            date: '2022-2024',
            des: 'Курс frontend-developer+ HTML|CSS|JS|REACT '
        },
        {
            title: 'Project engeneering course',
            date: '2023-2024',
            des: 'Курс создания проектов (стартапов) их разработки и развития'
        },
        {
            title: 'University education',
            date: 'Now',
            des: 'Казанский (Приволжский) Федеральный Университет по направлению Информационные Системы и Технологии '
        },
    ]

    const workExperience = [
        {
            title: 'EvenTracker',
            date: '2022-2024',
            des: 'REACT js | JS | HTML | CSS'
        },
        {
            title: 'Alumni',
            date: '2023-2024',
            des: 'NEXT js | JS | HTML | CSS | TAILWIND CSS '
        },
        {
            title: 'Friendify',
            date: '2024-2024',
            des: 'REACT js | JS | HTML | CSS'
        },
    ]

    const achivements = [
        {
            title: 'Future now',
            date: '2022-2024',
            des: 'Победа в конкурсе проектов по развитию города среди молодежи'
        },
        {
            title: 'Digital Chalenge',
            date: '2023-2024',
            des: 'Победа в Хакатоне по разработке веб приложений'
        },
    ]

    return {
        workExperience,
        achivements,
        education
    }


}