import React, { useEffect, useState } from 'react'
import './TimeLine.css'
import { useAchivements } from '../../Hooks/useAchivements'

function TimeLine({setVariant, type}) {

  const {achivements, education, workExperience} = useAchivements()
  const [arr, setArr] = useState(education)
  
  let prev;
  useEffect(() => {
    const nav = document.getElementById('navBar')
    nav.addEventListener('click', e => {
      prev ? document.getElementById(prev).classList.remove('selected') : <></>
      e.target.classList.add('selected')
      prev = e.target.id
      switch (e.target.id) {
        case 'time1':
          setArr(education)
          break;

        case 'time2':
          setArr(workExperience)
          break;

        case 'time3':
          setArr(achivements)
          break;

        default:
          break;
      }
    })
  }, []) 


  useEffect(() => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === 'render') {
        anim.cancel();
        anim.play();
      }
    })
  }, [arr])

  return (
    type === 'mobile' ? 
    <div className='w-[100%] h-screen overflow-scroll timeline-wrapper'>
      <div className=''>
        {arr.map((item, index) => {
          return (
          <div key={crypto.randomUUID()} className='mobile-time-line w-[90%] ml-[2.5%] pl-[7.5%] flex flex-col items-center justify-center h-[300px] border-l-4 border-l-progress_line relative'>
            <div className='dot'></div>
            <div className='date'>{item.date}</div>
            <div className='content-line-item h-[200px]'>
              <h1 className='font-bold text-xl'>{item.title}</h1>
              <p className='text-ms'>{item.des}</p>
            </div>
          </div>
          )
        })}
      </div>
    </div>
    :
    <div className='w-[90%] h-screen overflow-scroll timeline-wrapper'>
      <div className='timeline-items'>
        {arr.map((item, index) => {
          return (
          <div key={crypto.randomUUID()} className='timeline-item'>
            <div className='timeline-dot'></div>
            <div className='timeline-date'>{item.date}</div>
            <div className='timeline-content'>
              <h1>{item.title}</h1>
              <p>{item.des}</p>
            </div>
          </div>
          )
        })}
      </div>
    </div>
  )
}

export default TimeLine