import React, { useEffect, useState } from 'react'
import '../css/main.css'
import { isMobile } from 'react-device-detect'
import ProjectsSlider from '../components/3DSlider/ProjectsSlider'
import { useProjects } from '../Hooks/useProjects'  
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { useNavigate } from 'react-router'
import { Icon28GridLayoutOutline } from '@vkontakte/icons'

function ProjectsTab() {
  
  const { projects } = useProjects()
  const navigate = useNavigate()



  return (
    isMobile?
    <div className='page_format flex items-center justify-center '>
      <div className='w-[90vw] h-screen flex py-[5.5vh] items-center justify-evenly flex-col'>
        <h1 className='text-3xl font-bold '>Projects created by myself and my inspiration</h1>
        <Swiper
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards]}
          className="mySwiper"
        >
          {projects.map((item) => {
            return (
              <SwiperSlide key={crypto.randomUUID()}>
                <div style={{backgroundImage: `url(${item.img})`, backgroundAttachment: 'center'}} className='swiper-slide-content'>
                  <div className='z-10 relative w-[90%] h-[90%] overflow-hidden'>
                    <div className='flex flex-col mt-[70%]'>
                      <h1 className='text-3xl font-extrabold'>{item.title}</h1>
                      <h1 className='mt-[1vh] opacity-80 font-[600]'>{item.topic}</h1>
                    </div>
                  </div>
                  <div className='absolute w-full h-full left-0 top-0 bg-bg_primary opacity-30 z-0'/>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className='w-full h-auto flex items-center justify-center'>
          <button onClick={() => navigate('/projects')} className='hover:scale-125 duration-200 hover:duration-200 border-2 border-[#47feec] px-[5vw] py-[2vh] rounded-2xl'>
            see all projects
          </button>
        </div>
      </div>
    </div>  
    :
    <div className='page_format'>
      <ProjectsSlider/>
    </div>
  )
}

export default ProjectsTab